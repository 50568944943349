<template lang="pug">
  section.events
    .events__container
      .events__content
        h2.events__title.title
          | Тренування у
          |
          span VIDAR
        .events__text
          p Слідкуйте за останніми заходами спортивного центру.
          p Будемо раді Вас бачити!
        router-link.events__link.link(to="schedule") Розклад тренувань
      .events__wrapper-slider
        vue-slick-carousel.events__slider(v-bind="settingsEvents")
          .events__slide(
            v-for="(item, index) in eventsSlides"
            :key="index"
          )
            .events__day
              span {{ item.eventsDay }}
            p.events__time {{ item.eventsTime }}
            .events__table
              .events__item Тренування:
              .events__item Спас, Бокс, Дзюдо
            a.link(href="tel:+380687835335") Запис
</template>

<script>
// vue-slick-carousel
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'EventsBlock',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      eventsSlides: [
        {
          eventsDay: 'Понеділок',
          eventsTime: '13:30-18:00'
        },
        {
          eventsDay: 'Вівторок',
          eventsTime: '13:30-21:00'
        },
        {
          eventsDay: 'Середа',
          eventsTime: '13:30-18:00'
        },
        {
          eventsDay: 'Четвер',
          eventsTime: '13:30-21:00'
        },
        {
          eventsDay: 'П"ятниця',
          eventsTime: '13:30-18:00'
        },
        {
          eventsDay: 'Субота',
          eventsTime: '10:00-15:00'
        }
      ],
      settingsEvents: {
        arrows: true,
        dots: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  }
}
</script>
